<template>
  <div class="item columns is-mobile">
    <div class="column is-narrow col-img">
      <TrustYouRanked
        class="is-size-7 ribun" layout="vertical" v-bind="ranking && ranking.badgeData" />
      <PropertyGalleryHover
        class="property--gallery"
        :gallery="property.gallery"
        heroSize="large"
        :heroImage="property.heroImage" />
    </div>
    <div class="column col-content">
      <div class="columns is-multiline is-gapless col-cotnent-wrapper">
        <div class="column">
          <h6 class="has-text-dark prop-name has-text-weight-semibold subtitle is-6 is-marginless">{{ property.name }}</h6>
          <PropertyAddress
            class="is-size-7 is-block"
            :params="$route.params"
            :query="$route.query"
            :mapLink="mapLink"
            :location="property && property.location" />
          <p class="is-size-7 is-italic has-text-grey" v-if="reviews.summary.location">~ {{ reviews.summary.location.text }}</p>
          <div class="columns">
            <StarRatings class="prop-star is-size-7 column is-narrow" :ratings="property.starRating" />&nbsp;
            <TrustYouPopularWith
              layout="text"
              class="is-size-7 column has-text-dark"
              v-bind="reviews.summary.popularWith" />

          </div>
        </div>
        <div class="column">
          <PpGap size="small" />
          <TrustYouHotelType
            class="subtitle is-7 has-text-grey"
            v-for="it in reviews.hotelTypeList"
            :key="it.categoryId"
            textType="shortText"
            v-bind="it"
            />
            <!-- {{ reviews.summary.reviewsDistribution }} -->
           <!-- <BadgeOutline
            :noMargin="true"
            class="is-size-7 is-inline-block">
              <TrustYouRecommendation class="is-size-7" :reviewsDistribution="reviews.summary && reviews.summary.reviewsDistribution" />
           </BadgeOutline> -->
        </div>
        <div class="column is-narrow col-pckg-details">
          <div style="flex: 1;">
            <!-- {{ lowestPckg.nonRefundable }} -->
            <PackagesFoodCode
              class="is-size-7"
              :hideRoomOnly="true"
              :foodCode="lowestPckg.foodCode" />
          </div>
          <div class="has-text-right" v-opacity-zero="!marketRates[0]">
            <div class="is-size-7 has-text-grey">{{ marketRates[0] && marketRates[0].supplier }}</div>
            <div class="is-size-6 has-text-grey">
              {{ marketRates[0] && marketRates[0] | currencyObj({ factionDigits: 0 }) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="column col-price has-text-right is-narrow">
      <div class="columns is-multiline is-gapless col-cotnent-wrapper">
        <div class="column">
          <TrustYouRating
            :condensed="true"
            size="s"
            v-bind="property.trustYou" />
        </div>
        <div class="column">
          <TrustYouBadgeList
            style="font-size: 0.6em;"
            :popularityThreshold="3"
            :singleMode="true"
            :limit="1"
            :badgeList="reviews.badgeList"/>
        </div>
        <div class="column is-size-6 has-text-black is-narrow">
          <AdjustmentBadge
            class="is-size-7"
            :adjustmentPct="lowestPckg.initialAdjustmentPct" />
          <PpGap />
          <div class="is-size-7 has-text-grey">Nightly avg.</div>
          <div class="has-text-line-through subtitle is-6 has-text-grey is-marginless"
            v-if="lowestPckg.initialAdjustmentPct < -0.00">
            {{ displayRate | currencyObj({ factionDigits: 0 }) }}
          </div>
          <div class="is-4  subtitle has-text-weight-semibold has-text-dark">{{ adjustedDisplayRate | currencyObj({ factionDigits: 0 }) }}</div>
        </div>
      <PriceAudit v-if="lowestPckg.raw" :raw="lowestPckg.raw" />
      </div>
    </div>
  </div>
</template>

<script>
import PriceAudit from './PriceAudit'
import PropertyAddress from '@/components/PropertyAddress'
import StarRatings from '@/components/StarRatings'
import AdjustmentBadge from '@/components/AdjustmentBadge'
import PropertyGalleryHover from '@/components/PropertyGalleryHover'
import BadgeOutline from '@/components/BadgeOutline'
import TrustYouRating from '@/components/TrustYouRating'
import TrustYouRanked from '@/components/TrustYouRanked'
import TrustYouPopularWith from '@/components/TrustYouPopularWith'
import TrustYouHotelType from '@/components/TrustYouHotelType'
import TrustYouRecommendation from '@/components/TrustYouRecommendation'
import TrustYouBadgeList from '@/components/TrustYouBadgeList'
import PackagesFoodCode from './PackagesFoodCode'

export default {
  name: 'Property',
  components: {
    [AdjustmentBadge.name]: AdjustmentBadge,
    [PropertyGalleryHover.name]: PropertyGalleryHover,
    [PropertyAddress.name]: PropertyAddress,
    [PackagesFoodCode.name]: PackagesFoodCode,
    [BadgeOutline.name]: BadgeOutline,
    [StarRatings.name]: StarRatings,
    [TrustYouRanked.name]: TrustYouRanked,
    [TrustYouRating.name]: TrustYouRating,
    [TrustYouPopularWith.name]: TrustYouPopularWith,
    [TrustYouHotelType.name]: TrustYouHotelType,
    [TrustYouRecommendation.name]: TrustYouRecommendation,
    [TrustYouBadgeList.name]: TrustYouBadgeList,
    [PriceAudit.name]: PriceAudit
  },
  props: {
    property: Object,
    packages: Array,
    nights: Number,
    layout: String
  },
  data () {
    return {
      inNightlyAvgRate: true
    }
  },
  computed: {
    mapLink () {
      return this.layout !== 'simple'
    },
    ranking () {
      return this.reviews.badgeList && this.reviews.badgeList.find(({ badgeType }) => badgeType === 'ranking') || {}
    },
    reviews () {
      return this.property.reviews && this.property.reviews.summary && this.property.reviews || { summary: {}, badgeList: [], hotelTypeList: [] }
    },
    lowestPckg () {
      return this.packages[0] || {}
    },
    marketRates () {
      return (this.inNightlyAvgRate ? this.lowestPckg.unitMarketRates : this.lowestPckg.marketRates) || []
    },
    displayRate () {
      return this.inNightlyAvgRate ? this.lowestPckg.unitDisplayRate : this.lowestPckg.displayRate
    },
    adjustedDisplayRate () {
      return this.inNightlyAvgRate ? this.lowestPckg.unitAdjustedDisplayRate : this.lowestPckg.adjustedDisplayRate
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/bulma-variables';
  .item {
    // padding: ($bleed * 2) $bleed;
    margin: 0;
    // justify-content: space-between;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .property--gallery {
    height: 100%;
  }
  .col-img {
    padding: 0;
  }
  .col-content {
    .col-cotnent-wrapper {
      height: 100%;
      flex-direction: column;
    }
  }
  .col-pckg-details {
    display: flex;
    align-items: flex-end;
  }
  .col-price {
    width: 160px;
    // min-width: 200px;
    // align-self: center;
    .col-cotnent-wrapper {
      height: 100%;
      flex-direction: column;
    }
    // background-color: #fff;
    // padding: $bleed / 2 $bleed !important;
    // align-self: flex-end;
    // box-shadow: inset 0 0 50px 20px rgba(255, 255, 255, 0.9);
  }
  .ribun {
    position: absolute;
    z-index: 1;
    margin: $bleed $bleed $bleed -#{$bleed * 2};
  }
    .col-label {
      // background-color: #fff;
      // box-shadow: inset 0 0 50px 20px rgba(255, 255, 255, 0.9);
      // padding: $bleed / 2 $bleed;
      // flex: 1;
      // margin-left: - ($bleed * 2) + 2;
      // order: -2;
      .prop-name {
        // display: inline-block;
        // box-shadow: inset 0 0 50px 20px rgba(255, 255, 255, 0.9);
      }
      .prop-star {
        // display: inline-block;
        // padding: $bleed / 2 $bleed;
        // box-shadow: inset 0 0 50px 20px rgba(255, 255, 255, 0.9);
      }
    }
    .col-public-rate {
      // background-color: #fff;
      // padding: $bleed / 2 $bleed;
      // align-self: flex-start;
      // box-shadow: inset 0 0 50px 20px rgba(255, 255, 255, 0.9);
    }
    .col-adjustment {
      // background-color: #fff;
      // padding: $bleed / 2 $bleed;
      // box-shadow: inset 0 0 50px 20px rgba(255, 255, 255, 0.9);
    }
    .item-gap {
      margin-top: $bleed * 25;
    }
  // .fixed-height {
  //   height: calc(100vh - #{$zu-header-height} - 112px - 50px);
  //   overflow-y: scroll;
  // }
  // #map-canvas {
  //   height: calc(100vh - #{$zu-header-height} - 112px);
  //   width: 100%;
  // }
</style>
