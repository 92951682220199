<template>
  <div class="item columns is-mobile is-multiline">
    <div class="column col-label">
      <div>
        <strong class="has-text-dark supplier-description is-block">{{ pckg.supplierDescription || pckg.description }}</strong>
      </div>
      <PackagesFoodCode :foodCode="pckg.foodCode" :hideDescription="$mq.mobile"/>
    </div>
    <div class="column col-adjustment has-text-right-tablet is-2-tablet"
      v-opacity-zero="!(pckg.initialAdjustmentPct < -0.00)">
      <div class="is-size-7 has-text-grey"
        v-if="!marketRates[0]">&nbsp;</div>
      <div class="is-size-6 has-text-grey"
        v-else>
        <!-- <div class="is-size-7 has-text-grey">Public rate</div> -->
        <div class="is-size-7 has-text-grey">{{ marketRates[0] && marketRates[0].supplier }}</div>
        {{ marketRates[0] && marketRates[0] | currencyObj }}
      </div>
      <AdjustmentBadge
        class="is-size-7"
        :adjustmentPct="pckg.initialAdjustmentPct" />
    </div>
    <div class="column col-price has-text-right is-4-tablet">
      <div class="has-text-black">
        <div class="is-size-7 has-text-grey">Nightly avg.</div>
        <div class="has-text-line-through has-text-grey"
          v-if="pckg.initialAdjustmentPct < -0.00">
          {{ displayRate | currencyObj }}
        </div>
        <strong class="is-size-5 has-line-height-1-125">{{ adjustedDisplayRate | currencyObj }}</strong>
      </div>
      <PriceAudit v-if="pckg.raw" :raw="pckg.raw" />
    </div>
    <div
      v-if="$slots['call-to-action']"
      class="column col-action is-narrow-tablet has-text-right">
      <slot name="call-to-action">
      </slot>
    </div>
  </div>
</template>

<script>
import AdjustmentBadge from './AdjustmentBadge'
import PriceAudit from './PriceAudit'
import PackagesFoodCode from './PackagesFoodCode'

export default {
  name: 'Packages',
  components: {
    [AdjustmentBadge.name]: AdjustmentBadge,
    [PriceAudit.name]: PriceAudit,
    [PackagesFoodCode.name]: PackagesFoodCode
  },
  props: {
    pckg: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      inNightlyAvgRate: true
    }
  },
  computed: {
    nonRefundable () {
      return this.pckg.nonRefundable == null
    },
    marketRates () {
      return this.inNightlyAvgRate ? this.pckg.unitMarketRates : this.pckg.marketRates
    },
    displayRate () {
      return this.inNightlyAvgRate ? this.pckg.unitDisplayRate : this.pckg.displayRate
    },
    adjustedDisplayRate () {
      return this.inNightlyAvgRate ? this.pckg.unitAdjustedDisplayRate : this.pckg.adjustedDisplayRate
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../styles/bulma-variables';

  .supplier-description {
    text-transform: uppercase;
  }
  .item {
    padding: ($bleed * 2) $bleed;
    margin: 0;
    // justify-content: space-between;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .col-label {
    padding: $bleed / 2 $bleed;
    .prop-name {
    }
    .prop-star {
    }
  }
  .col-public-rate {
    padding: $bleed / 2 $bleed;
  }
  .col-adjustment {
    padding: $bleed / 2 $bleed;
  }
  .col-price {
    padding: $bleed / 2 $bleed !important;
  }
  @include mobile {
    .item {
      justify-content: space-between;
    }
    .col-label {
      order: 1;
    }
    .col-price {
      order: 2;
    }
    .col-public-rate {
      order: 4;
    }
    .col-adjustment {
      order: 3;
    }
    .col-action {
      order: 5;
    }
  }
</style>
