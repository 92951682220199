<template>
  <div v-if="raw">
    <el-popover
      ref="popover"
      placement="left"
      offset="10"
      v-model="visible"
      trigger="hover">
      <div class="price-audit">
        <div class="list is-narrow is-small has-shadow-bottom has-text-weight-semibold">
          <div class="list-action" style="width: 160px;">Key</div>
          <div class="list-action" style="width: 100px;">Amount</div>
          <div class="list-action" style="width: 80px;">Ccy</div>
          <div class="list-content">Description</div>
        </div>
        <div class="list is-narrow is-small has-shadow-bottom"
          v-for="it in raw"
          :key="it.key">
          <div class="list-action" style="width: 160px;">{{ it.key }}</div>
          <div class="list-action has-text-dark" style="width: 100px;">{{ it.val }}</div>
          <div class="list-action has-text-dark" style="width: 80px;">{{ it.ccy }}</div>
          <div class="list-content has-text-left">{{ it.des }}</div>
        </div>
      </div>
      <a class="is-size-7" slot="reference">{{ content }}</a>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: 'PriceAudit',
  props: {
    raw: {
      type: Array,
      required: true
    },
    content: {
      type: String,
      default: 'Why?'
    }
  },
  data () {
    return {
      visible: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .price-audit {
    font-size: 12px;
    min-width: 310px;
    max-width: 550px;
  }
  .list-action {
    flex: none;
  }
  .list-content {
    word-break: break-all;
  }
</style>
